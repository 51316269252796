import $ from 'jquery';

global.$ = global.jQuery = window.$ = window.jQuery = $;

import 'babel-polyfill';

import Layout from './modules/layout';
import Common from './modules/common';

$().ready(e => {
	let layout = new Layout();
	layout.init();

	let common = new Common();
	common.init();
});
