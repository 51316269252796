import * as CONST from '../utils/constants';
import Util from '../utils/ui';

class Layout {
	constructor() {

	}

	init() {
		this.bindEvents();
	}

	bindEvents() {
		$('.js-nav-toggle').on('click', e => { this.mobileNav(e) });
	}

	mobileNav(e) {
		let target = $(e.currentTarget);

		target.toggleClass('open');
		$('.js-nav').toggleClass('open');
	}
}

export default Layout;