import * as CONST from '../utils/constants';
import Util from '../utils/ui';
import mask from 'jquery-mask-plugin';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import $ from 'jquery';

class Common {

	constructor() {

	}

	init() {
		this.bindEvents();
	}

	bindEvents() {
        $('form').validate();

        // phone mask
        $('.js-phone-input').mask('(000) 000-0000');
        $('#contact-form').on('submit', e => { this.sendEmail(e); });
    }	

    sendEmail(e) {
        e.preventDefault();
        e.stopPropagation();

        if (grecaptcha.getResponse().length === 0) {
            e.preventDefault();
            $('#recaptcha_contact').next().show();
        }
        else {
            $('#recaptcha_contact').next().hide();

            if ($('#contact-form').valid()) {

                $.ajax({
                    type: 'POST',
                    url: '/contact',
                    data: $('#contact-form').serialize(), // serializes the form's elements.
                    success: function (data) {
                        // success, show thank you and hide form
                        $('#contact-form').css('display', 'none');
                        $('.thank-you-message').css('display', 'block');
                    }
                });
            }
        }
    }
}

export default Common;