
export const ViewPort = {
	Desktop: 'desktop',
	Tablet: 'tablet',
	Mobile: 'mobile'
}

export const Breakpoints = {
	Desktop: '970',
	Tablet: '750'
}